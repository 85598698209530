exports.components = {
  "component---505320965-radeoffs-with-a-diverse-set-of-concerns-and-stakeholders-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/the-socio-technical-dilemma-fleshing-out-tradeoffs-with-a-diverse-set-of-concerns-and-stakeholders.mdx" /* webpackChunkName: "component---505320965-radeoffs-with-a-diverse-set-of-concerns-and-stakeholders-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cfp-js": () => import("./../../../src/pages/cfp.js" /* webpackChunkName: "component---src-pages-cfp-js" */),
  "component---src-pages-coc-js": () => import("./../../../src/pages/coc.js" /* webpackChunkName: "component---src-pages-coc-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-diversity-js": () => import("./../../../src/pages/diversity.js" /* webpackChunkName: "component---src-pages-diversity-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hotel-js": () => import("./../../../src/pages/hotel.js" /* webpackChunkName: "component---src-pages-hotel-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-openspace-js": () => import("./../../../src/pages/openspace.js" /* webpackChunkName: "component---src-pages-openspace-js" */),
  "component---src-pages-program-index-js": () => import("./../../../src/pages/program/index.js" /* webpackChunkName: "component---src-pages-program-index-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-shuttle-js": () => import("./../../../src/pages/shuttle.js" /* webpackChunkName: "component---src-pages-shuttle-js" */),
  "component---src-pages-speakers-index-js": () => import("./../../../src/pages/speakers/index.js" /* webpackChunkName: "component---src-pages-speakers-index-js" */),
  "component---src-pages-sponsor-us-js": () => import("./../../../src/pages/sponsor-us.js" /* webpackChunkName: "component---src-pages-sponsor-us-js" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-values-js": () => import("./../../../src/pages/values.js" /* webpackChunkName: "component---src-pages-values-js" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-advanced-software-architecture-with-residuality-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/advanced-software-architecture-with-residuality.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-advanced-software-architecture-with-residuality-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-aggregates-composition-a-new-view-on-aggregates-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/aggregates-composition-a-new-view-on-aggregates.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-aggregates-composition-a-new-view-on-aggregates-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-all-events-are-testable-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/all-events-are-testable.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-all-events-are-testable-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-applying-systems-thinking-in-teams-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/applying-systems-thinking-in-teams.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-applying-systems-thinking-in-teams-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-architecture-the-hard-parts-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/architecture-the-hard-parts.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-architecture-the-hard-parts-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-baking-domain-concepts-into-code-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/baking-domain-concepts-into-code.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-baking-domain-concepts-into-code-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-balancing-coupling-in-software-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/balancing-coupling-in-software-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-balancing-coupling-in-software-design-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-better-ddd-with-systems-engineering-thinking-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/better-ddd-with-systems-engineering-thinking.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-better-ddd-with-systems-engineering-thinking-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-beyond-aggregates-advanced-patterns-for-use-with-event-sourced-systems-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/beyond-aggregates-advanced-patterns-for-use-with-event-sourced-systems.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-beyond-aggregates-advanced-patterns-for-use-with-event-sourced-systems-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-blink-modelling-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/blink-modelling.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-blink-modelling-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-bridging-the-gap-residuality-theory-and-domain-driven-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/bridging-the-gap-residuality-theory-and-domain-driven-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-bridging-the-gap-residuality-theory-and-domain-driven-design-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-collaborative-software-design-how-to-facilitate-design-decisions-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/collaborative-software-design-how-to-facilitate-design-decisions.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-collaborative-software-design-how-to-facilitate-design-decisions-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-collective-architecture-live-rpg-experience-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/collective-architecture-live-rpg-experience.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-collective-architecture-live-rpg-experience-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-concepts-infused-in-metaphor-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/concepts-infused-in-metaphor.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-concepts-infused-in-metaphor-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-core-domain-chart-explore-your-digital-transformation-in-3-d-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/core-domain-chart-explore-your-digital-transformation-in-3d.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-core-domain-chart-explore-your-digital-transformation-in-3-d-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-creating-magical-moments-applying-liberating-structures-to-collaborative-modeling-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/creating-magical-moments-applying-liberating-structures-to-collaborative-modeling.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-creating-magical-moments-applying-liberating-structures-to-collaborative-modeling-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-ddd-and-fp-cant-be-friends-yet-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/ddd-and-fp-cant-be-friends-yet.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-ddd-and-fp-cant-be-friends-yet-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-ddd-in-large-product-portfolios-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/ddd-in-large-product-portfolios.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-ddd-in-large-product-portfolios-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-devs-lets-reclaim-ddd-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/devs-lets-reclaim-ddd.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-devs-lets-reclaim-ddd-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-discover-and-define-data-products-in-a-data-mesh-way-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/discover-and-define-data-products-in-a-data-mesh-way.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-discover-and-define-data-products-in-a-data-mesh-way-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-domain-recommoning-platform-thinking-with-the-three-economies-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/domain-recommoning-platform-thinking-with-the-three-economies.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-domain-recommoning-platform-thinking-with-the-three-economies-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-domain-storytelling-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/domain-storytelling.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-domain-storytelling-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-entropy-in-software-ddd-and-constructor-theory-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/entropy-in-software-ddd-and-constructor-theory.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-entropy-in-software-ddd-and-constructor-theory-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-event-driven-architecture-and-governance-in-action-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/event-driven-architecture-and-governance-in-action.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-event-driven-architecture-and-governance-in-action-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-event-sourcing-in-a-serverless-world-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/event-sourcing-in-a-serverless-world.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-event-sourcing-in-a-serverless-world-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-event-sourcing-in-action-insights-from-two-real-life-projects-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/event-sourcing-in-action-insights-from-two-real-life-projects.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-event-sourcing-in-action-insights-from-two-real-life-projects-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-eventstorming-masterclass-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/eventstorming-masterclass.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-eventstorming-masterclass-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-evolution-patterns-of-sociotechnical-systems-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/evolution-patterns-of-sociotechnical-systems.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-evolution-patterns-of-sociotechnical-systems-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-expanding-your-design-heuristic-repertoire-one-experiment-at-a-time-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/expanding-your-design-heuristic-repertoire-one-experiment-at-a-time.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-expanding-your-design-heuristic-repertoire-one-experiment-at-a-time-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-exploring-the-elasticity-of-models-frameworks-and-heuristics-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/exploring-the-elasticity-of-models-frameworks-and-heuristics.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-exploring-the-elasticity-of-models-frameworks-and-heuristics-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-extreme-modelling-patterns-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/extreme-modelling-patterns.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-extreme-modelling-patterns-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-from-vision-to-code-functional-domain-modelling-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/from-vision-to-code-functional-domain-modelling.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-from-vision-to-code-functional-domain-modelling-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-impact-mapping-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/impact-mapping.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-impact-mapping-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-interactively-implementing-a-domain-model-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/interactively-implementing-a-domain-model.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-interactively-implementing-a-domain-model-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-kiss-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/kiss.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-kiss-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-lets-craft-automated-refactorings-ourselves-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/lets-craft-automated-refactorings-ourselves.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-lets-craft-automated-refactorings-ourselves-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-living-in-your-own-bubble-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/living-in-your-own-bubble.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-living-in-your-own-bubble-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-maintaining-open-source-eventstore-and-working-with-it-in-cashflow-management-system-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/maintaining-open-source-eventstore-and-working-with-it-in-cashflow-management-system.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-maintaining-open-source-eventstore-and-working-with-it-in-cashflow-management-system-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-model-mitosis-a-dynamic-pattern-to-deal-with-model-tensions-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/model-mitosis-a-dynamic-pattern-to-deal-with-model-tensions.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-model-mitosis-a-dynamic-pattern-to-deal-with-model-tensions-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-modelling-constraints-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/modelling-constraints.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-modelling-constraints-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-naming-is-caring-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/naming-is-caring.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-naming-is-caring-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-parrot-emergency-evaluating-risks-in-your-application-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/parrot-emergency-evaluating-risks-in-your-application.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-parrot-emergency-evaluating-risks-in-your-application-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-platform-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/platform-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-platform-design-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-playing-with-domain-models-in-code-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/playing-with-domain-models-in-code.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-playing-with-domain-models-in-code-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-practical-ddd-transforming-theories-into-guidelines-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/practical-ddd-transforming-theories-into-guidelines.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-practical-ddd-transforming-theories-into-guidelines-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-practical-introduction-to-event-sourcing-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/practical-introduction-to-event-sourcing.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-practical-introduction-to-event-sourcing-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-programming-and-reasoning-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/programming-and-reasoning.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-programming-and-reasoning-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-projections-for-gamification-in-a-social-app-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/projections-for-gamification-in-a-social-app.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-projections-for-gamification-in-a-social-app-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-refactoring-enabling-refactorings-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/refactoring-enabling-refactorings.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-refactoring-enabling-refactorings-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-refactoring-legacy-code-guided-by-approval-tests-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/refactoring-legacy-code-guided-by-approval-tests.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-refactoring-legacy-code-guided-by-approval-tests-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-refactoring-to-a-really-small-but-useful-model-island-inside-a-sea-of-legacy-code-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/refactoring-to-a-really-small-but-useful-model-island-inside-a-sea-of-legacy-code.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-refactoring-to-a-really-small-but-useful-model-island-inside-a-sea-of-legacy-code-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-security-fails-at-the-seams-using-domain-modelling-to-test-a-hypothesis-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/security-fails-at-the-seams-using-domain-modelling-to-test-a-hypothesis.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-security-fails-at-the-seams-using-domain-modelling-to-test-a-hypothesis-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-slim-your-aggregates-with-an-event-driven-approach-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/slim-your-aggregates-with-an-event-driven-approach.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-slim-your-aggregates-with-an-event-driven-approach-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-slow-code-retreat-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/slow-code-retreat.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-slow-code-retreat-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-software-design-masterclass-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/software-design-masterclass.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-software-design-masterclass-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-sponsored-talk-beyond-the-hype-an-interactive-exploration-of-event-sourcing-and-eventstoredb-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/sponsored-talk-beyond-the-hype-an-interactive-exploration-of-event-sourcing-and-eventstoredb.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-sponsored-talk-beyond-the-hype-an-interactive-exploration-of-event-sourcing-and-eventstoredb-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-strategic-domain-driven-refactorings-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/strategic-domain-driven-refactorings.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-strategic-domain-driven-refactorings-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-system-design-and-technical-decision-making-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/system-design-and-technical-decision-making.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-system-design-and-technical-decision-making-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-systems-thinking-in-large-scale-modeling-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/systems-thinking-in-large-scale-modeling.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-systems-thinking-in-large-scale-modeling-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-tdd-and-ddd-from-the-ground-up-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/tdd-and-ddd-from-the-ground-up.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-tdd-and-ddd-from-the-ground-up-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-tdd-beyond-the-intro-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/tdd-beyond-the-intro.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-tdd-beyond-the-intro-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-temporal-patterns-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/temporal-patterns.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-temporal-patterns-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-the-escher-school-of-fish-modelling-with-functions-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/the-escher-school-of-fish-modelling-with-functions.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-the-escher-school-of-fish-modelling-with-functions-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-the-little-pattern-that-could-leveraging-the-power-of-repositories-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/the-little-pattern-that-could-leveraging-the-power-of-repositories.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-the-little-pattern-that-could-leveraging-the-power-of-repositories-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-the-magic-of-real-collaboration-between-engineers-designers-and-pms-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/the-magic-of-real-collaboration-between-engineers-designers-and-pms.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-the-magic-of-real-collaboration-between-engineers-designers-and-pms-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-the-scale-up-the-autonomy-and-the-nuclear-submarine-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/the-scale-up-the-autonomy-and-the-nuclear-submarine.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-the-scale-up-the-autonomy-and-the-nuclear-submarine-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-tidy-first-a-daily-practice-of-empirical-software-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/tidy-first-a-daily-practice-of-empirical-software-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-tidy-first-a-daily-practice-of-empirical-software-design-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-wardley-mapping-getting-started-with-collaborative-mapping-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/wardley-mapping-getting-started-with-collaborative-mapping.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-wardley-mapping-getting-started-with-collaborative-mapping-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-when-customers-dont-share-a-language-modelling-deep-dive-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/when-customers-dont-share-a-language-modelling-deep-dive.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-when-customers-dont-share-a-language-modelling-deep-dive-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-wired-how-your-brain-learns-new-programming-languages-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/wired-how-your-brain-learns-new-programming-languages.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-wired-how-your-brain-learns-new-programming-languages-mdx" */),
  "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-zero-downtime-projections-replay-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/program/zero-downtime-projections-replay.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-program-zero-downtime-projections-replay-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-adriana-nitescu-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/adriana-nitescu.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-adriana-nitescu-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-alberto-acerbis-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/alberto-acerbis.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-alberto-acerbis-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-alberto-brandolini-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/alberto-brandolini.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-alberto-brandolini-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-alessandro-colla-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/alessandro-colla.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-alessandro-colla-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-alexandra-lung-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/alexandra-lung.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-alexandra-lung-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-alexey-zimarev-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/alexey-zimarev.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-alexey-zimarev-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-amal-tahri-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/amal-tahri.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-amal-tahri-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-andreas-pinhammer-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/andreas-pinhammer.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-andreas-pinhammer-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-andrew-harmel-law-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/andrew-harmel-law.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-andrew-harmel-law-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-anita-kvamme-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/anita-kvamme.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-anita-kvamme-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-anton-stockl-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/anton-stockl.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-anton-stockl-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-arnaud-thiefaine-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/arnaud-thiefaine.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-arnaud-thiefaine-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-ayesha-bagus-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/ayesha-bagus.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-ayesha-bagus-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-barry-oreilly-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/barry-oreilly.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-barry-oreilly-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-chelsea-troy-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/chelsea-troy.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-chelsea-troy-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-chris-moore-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/chris-moore.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-chris-moore-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-chris-simon-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/chris-simon.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-chris-simon-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-cyrille-martraire-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/cyrille-martraire.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-cyrille-martraire-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-dana-bredemeyer-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/dana-bredemeyer.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-dana-bredemeyer-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-diana-montalion-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/diana-montalion.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-diana-montalion-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-dorra-bartaguiz-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/dorra-bartaguiz.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-dorra-bartaguiz-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-einar-host-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/einar-host.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-einar-host-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-eric-evans-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/eric-evans.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-eric-evans-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-ferdinand-ade-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/ferdinand-ade.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-ferdinand-ade-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-gien-verschatse-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/gien-verschatse.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-gien-verschatse-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-gilles-masy-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/gilles-masy.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-gilles-masy-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-hadi-ahmadi-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/hadi-ahmadi.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-hadi-ahmadi-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-henning-schwentner-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/henning-schwentner.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-henning-schwentner-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-hila-fox-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/hila-fox.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-hila-fox-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-ivan-padabed-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/ivan-padabed.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-ivan-padabed-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-jabe-bloom-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/jabe-bloom.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-jabe-bloom-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-jacek-majchrzak-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/jacek-majchrzak.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-jacek-majchrzak-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-jacob-duijzer-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/jacob-duijzer.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-jacob-duijzer-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-james-geall-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/james-geall.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-james-geall-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-jan-van-ryswyck-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/jan-van-ryswyck.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-jan-van-ryswyck-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-jeremie-chassaing-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/jeremie-chassaing.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-jeremie-chassaing-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-jessica-gantier-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/jessica-gantier.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-jessica-gantier-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-jettro-coenradie-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/jettro-coenradie.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-jettro-coenradie-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-josian-chevalier-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/josian-chevalier.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-josian-chevalier-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-julien-topcu-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/julien-topcu.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-julien-topcu-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-kate-chapman-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/kate-chapman.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-kate-chapman-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-kent-beck-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/kent-beck.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-kent-beck-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-krisztina-hirth-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/krisztina-hirth.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-krisztina-hirth-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-lorraine-steyn-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/lorraine-steyn.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-lorraine-steyn-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-lukasz-reszke-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/lukasz-reszke.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-lukasz-reszke-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-marco-emrich-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/marco-emrich.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-marco-emrich-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-mark-richards-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/mark-richards.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-mark-richards-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-martin-gunther-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/martin-gunther.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-martin-gunther-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-masoud-bahrami-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/masoud-bahrami.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-masoud-bahrami-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-mathias-verraes-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/mathias-verraes.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-mathias-verraes-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-michel-grootjans-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/michel-grootjans.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-michel-grootjans-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-mike-sperber-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/mike-sperber.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-mike-sperber-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-moriel-schottlender-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/moriel-schottlender.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-moriel-schottlender-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-neal-ford-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/neal-ford.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-neal-ford-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-nico-krijnen-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/nico-krijnen.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-nico-krijnen-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-nicolas-carlo-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/nicolas-carlo.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-nicolas-carlo-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-oskar-dudycz-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/oskar-dudycz.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-oskar-dudycz-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-paul-rayner-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/paul-rayner.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-paul-rayner-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-pauline-jamin-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/pauline-jamin.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-pauline-jamin-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-philippe-bourgau-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/philippe-bourgau.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-philippe-bourgau-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-rebecca-wirfs-brock-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/rebecca-wirfs-brock.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-rebecca-wirfs-brock-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-robert-baelde-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/robert-baelde.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-robert-baelde-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-romeu-moura-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/romeu-moura.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-romeu-moura-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-sasha-rosenbaum-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/sasha-rosenbaum.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-sasha-rosenbaum-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-simone-de-gijt-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/simone-de-gijt.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-simone-de-gijt-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-sonal-premi-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/sonal-premi.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-sonal-premi-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-stefan-hofer-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/stefan-hofer.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-stefan-hofer-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-stijn-vannieuwenhuyse-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/stijn-vannieuwenhuyse.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-stijn-vannieuwenhuyse-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-thomas-coopman-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/thomas-coopman.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-thomas-coopman-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-thomas-pierrain-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/thomas-pierrain.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-thomas-pierrain-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-tobias-goeschel-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/tobias-goeschel.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-tobias-goeschel-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-tom-asel-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/tom-asel.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-tom-asel-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-vlad-khononov-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/vlad-khononov.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-vlad-khononov-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-wei-david-wang-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/wei-david-wang.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-wei-david-wang-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-wim-debreuck-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/wim-debreuck.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-wim-debreuck-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-xin-yao-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/xin-yao.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-xin-yao-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-yves-lorphelin-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/yves-lorphelin.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-yves-lorphelin-mdx" */),
  "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-yves-reynhout-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2023.dddeurope.com/2023.dddeurope.com/content/speakers/yves-reynhout.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-home-runner-work-2023-dddeurope-com-2023-dddeurope-com-content-speakers-yves-reynhout-mdx" */)
}

